import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { useCreditorSelf } from "@gocardless/api/dashboard/creditor";

import { useCreditorRestrictionStatus } from "src/common/hooks/useCreditorRestrictionStatus";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { useOrganisation } from "src/queries/organisation";
import { Currency } from "src/common/currencies";

interface CollectionsPermitted {
  collectionsEnabledTrackingAttribute: {
    lro_collections_enabled?: boolean;
    verification_status?: CreditorsVerificationStatus;
  };
  collectionsEnabled?: boolean;
  payoutsEnabled?: boolean;
  checkingLowRisk?: boolean;
  refreshCollectionsPermitted: () => void;
  isCurrencyProhibited: (currency: Currency) => boolean;
}

export const useCollectionsPermitted = (): CollectionsPermitted => {
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_COLLECTIONS_PRE_VERIFICATION_TOGGLE,
  });
  const {
    isUndergoingOnboardingVerifications,
    isUndergoingInitialOnboardingVerifications,
    mutate: refreshCreditorRestrictionStatus,
  } = useCreditorRestrictionStatus();
  const organisation = useOrganisation();
  const primaryCreditorId = organisation?.links?.primary_creditor;
  const { data: creditorResponse, mutate: refreshCreditor } = useCreditorSelf(
    primaryCreditorId || null
  );
  const { status } = useVerificationStatus();
  const creditor = creditorResponse?.creditors;
  const sddEnabled = creditor && creditor.sdd_enabled;
  const isCpvEnabled = isVariationOn && sddEnabled;

  const creditorCollections = !!creditor?.collections_permitted;
  const collectionsEnabled =
    isCpvEnabled &&
    creditorCollections &&
    creditor.verification_status !== CreditorsVerificationStatus.Successful;

  const payoutsEnabled = isCpvEnabled && !isUndergoingOnboardingVerifications;

  const refreshCollectionsPermitted = () => {
    refreshCreditorRestrictionStatus();
    refreshCreditor();
  };

  const checkingLowRisk =
    isUndergoingInitialOnboardingVerifications ||
    (sddEnabled && !creditorCollections) ||
    (!sddEnabled && creditorCollections);

  const collectionsEnabledTrackingAttribute = isVariationOn
    ? {
        lro_collections_enabled: collectionsEnabled,
        verification_status: status,
      }
    : {};

  function isCurrencyProhibited(currency: Currency) {
    return Boolean(
      creditor?.sdd_enabled && currency !== organisation?.domestic_currency
    );
  }

  return {
    collectionsEnabledTrackingAttribute,
    collectionsEnabled,
    payoutsEnabled,
    checkingLowRisk,
    refreshCollectionsPermitted,
    isCurrencyProhibited,
  };
};
